import {CONVERT_TO_MGDL} from "@/models/Patient";
import {DEBUG} from "@/main";

// Calculate creatinine clearance from serum creatinine by CockcroftGault
// @params sim Simulation
// return number (mL/min)
// Ref: https://www.mdcalc.com/calc/43/creatinine-clearance-cockcroft-gault-equation#evidence
// mL/min = (140 – age) × (weight, kg) × (0.85 if female) / (72 × Cr, mg/dL)
// Convert: 1 mg/dL = 0.01131222 µmol/L
export const calculateCockcroftGault = (sim) => {

    console.log('Using CockcroftGault method')
    let crcl = null
    if (sim.isLoaded()) {
        const patient = sim.simPatient
        if (patient && patient.age && sim.simDoses.length > 0) {
            const genderFactor = patient.gender === 'Female' ? 0.85 : 1
            const ageYears = patient.ageUnit === 'years' ? patient.age : patient.age / 365.25
            // @ts-ignore
            const crDose = sim.getCreatinine()[0]
            if (crDose && crDose.amount) {
                // scr in mg/dL
                let scr = crDose.amountUnit === 'umol/L' ? crDose.amount * CONVERT_TO_MGDL : crDose.amount * 1
                // Correction for Buelga 2005 which uses conventional scr calc
                if (scr && sim.simModel.code === 'BUEL2005') {
                    scr = (scr * 1.065) + 0.067;
                }
                // Adjust for BMI
                const bmi = patient.bmi()
                if (bmi) {
                    const weight = bmi < 18.5 ? patient.weightKg(false) : bmi < 25 ? patient.weightKg(true) : patient.weightKg(true, true)
                    crcl = (140 - ageYears) * weight * genderFactor / (72 * scr)
                    if (DEBUG) {
                        console.log(`CockcroftGault: creatinine: ${crDose.amount} applied weight: ${weight}kg -> crcl=${crcl}`)
                    }
                }
                // Cap with model capCrcl
                const cap = sim.simModel.default.capCrcl
                if (cap && crcl > cap) {
                    crcl = cap
                    if (DEBUG) {
                        console.log(`CockcroftGault: CrCl is capped: crcl=${crcl}`)
                    }
                }
            }
        }
    }
    return crcl
}

// Calculate drug clearance by model
// @params simModel with clearance coeff
// @params crcl : creatinine clearance (mL/min) or (L/h)
// @params bsa : body surface area (m2)
// @return drugCl (L/h)
export const drugClearance = (simModel, crcl, bsa) => {
    let drugCl = 0
    const clearance = simModel.clearance[0]
    switch(simModel.code) {
        case 'BUEL2005':
            // 'CL(Vanc)(L/h) = 1.08 × CLCR (Cockcroft-Gault modified, mL/min)'
            drugCl = crcl * clearance.coeff * (60 / 1000)
            break
        case 'ROBERTS2011':
            // 'CL(Vanc)(L/h) = 4.58 * (CrCL (Cockcroft-Gault, mL/min) /1.73 (m2) * BSA (m2) ) / 100'
            // drugCl = (clearance.coeff * ((crcl / 1.73) * bsa ))/ 100;
            drugCl = (clearance.coeff * ((crcl / bsa ) * 1.73))/ 100;
            break
        case 'UDY2015':
            // '[Pip] CL(L/h) = 16.3 × CLCR(urinary, mL/min)/100'
            drugCl = clearance.coeff * crcl / 100
            break
    }
    return drugCl
}
