<template>
  <div id='custom' class="my-4">
    <h4 class="card-title">Suggest a custom dose plan</h4>
    <div>
      <ValidationObserver ref="observer" v-slot="{ invalid }">
        <b-form @submit.prevent="runSimulation">
          <b-input-group>
            <label title="Dose amount">Dose</label>
            <ValidationProvider name="Dose" vid="initialDose" :rules="rules.dose" v-slot="v">
              <b-form-input
                  id="initDose"
                  v-model="form.amount"
                  type="number"
                  :number="true"
                  class="input-with-unit"
                  placeholder="Amount"
                  :state="getValidationState(v)"
                  aria-describedby="input-1-live-feedback"
              />
              <b-form-invalid-feedback id="input-1-live-feedback">{{ v.errors[0] }}</b-form-invalid-feedback>
            </ValidationProvider>
            <b-input-group-append>
              <ValidationProvider vid="doseUnit">
                <b-form-select
                    id="initDoseUnit" ref="doseUnit"
                    v-model="form.amountUnit"
                    class="unit-dropdown"
                    :options="units.dose"
                    :clearable="false"
                >
                </b-form-select>
              </ValidationProvider>
            </b-input-group-append>
          </b-input-group>
          <b-input-group>
            <label title="Infusion duration">Duration</label>
            <ValidationProvider name="Duration" vid="duration" :rules="rules.duration" v-slot="v">
              <b-form-input
                  id="duration"
                  v-model="form.duration"
                  type="number"
                  :number="true"
                  class="input-with-unit"
                  placeholder="Infusion duration"
                  :state="getValidationState(v)"
                  aria-describedby="input-2-live-feedback"
              />
              <b-form-invalid-feedback id="input-2-live-feedback">{{ v.errors[0] }}</b-form-invalid-feedback>
            </ValidationProvider>
            <b-input-group-append>
              <ValidationProvider vid="durationUnit">
                <b-form-select
                    id="durationUnit" ref="durationUnit"
                    v-model="form.durationUnit"
                    class="unit-dropdown"
                    :options="units.duration"
                    :clearable="false"
                >
                </b-form-select>
              </ValidationProvider>
            </b-input-group-append>
          </b-input-group>
          <b-input-group>
            <label title="Dosing interval">Interval</label>
            <ValidationProvider name="interval" vid="interval" :rules="rules.interval" v-slot="vs">
              <b-form-input
                  id="interval"
                  v-model="form.interval"
                  type="number"
                  :number="true"
                  class="input-with-unit"
                  placeholder="Dosing interval"
                  :state="getValidationState(vs)"
                  aria-describedby="input-3-live-feedback"
              />
              <b-form-invalid-feedback id="input-3-live-feedback">{{ vs.errors[0] }}</b-form-invalid-feedback>
            </ValidationProvider>
            <b-input-group-append>
              <ValidationProvider vid="intervalUnit">
                <b-form-select
                    id="intervalUnit"
                    v-model="form.intervalUnit"
                    class="unit-dropdown"
                    :options="units.interval"
                    :clearable="false"
                >
                </b-form-select>
              </ValidationProvider>
            </b-input-group-append>
          </b-input-group>
          <div class="form-check form-switch float-end w-75" v-if="sim.simAdministeredDoses.length <=0">
            <input id="switch-loading"
                   class="form-check-input"
                   type="checkbox"
                   v-model="form.loading"/>
            <label for="switch-loading"
                   class="form-check-label">Estimate a loading dose</label>
          </div>
          <b-form-row>
            <div class="float-end mb-5">
              <b-button class="btn-lg" variant="success"
                        title="Run a simulation with custom data"
                        :disabled="invalid || !isFormValid"
                        @click="runSimulation">RUN CUSTOM
              </b-button>
            </div>
          </b-form-row>
        </b-form>
      </ValidationObserver>
    </div>
    <div v-if="options.length > 0">
      <SimulationDoses :data="options" :method="sim.simDelivery.method" :show-calculation="true"/>
      <div class="float-end mb-5">
        <b-button variant="outline-secondary"
                  title="Clear data"
                  @click="clear">CLEAR CUSTOM
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import {units} from "@/models/utils/constants";
import {Simulation} from "@/models/Simulation";
import {Drug} from "@/models/Drug";
import {DrugModel} from "@/models/DrugModel";
import {Pathogen} from "@/models/Pathogen";
import {Target} from "@/models/Target";
import {Delivery} from "@/models/Delivery";
import {Patient} from "@/models/Patient";
import SimulationDoses from "@/components/display/SimulationDoses.vue";
import {Custom} from "@/models/Custom";

export default {
  name: "CustomForm",
  components: {SimulationDoses},
  props: {
    sim: {type: Simulation}
  },
  watch: {
    sim() {
      this.loadSim()
    }
  },
  mounted() {
    this.loadSim()
  },
  data() {
    return {
      units: units,
      options: [],
      form: this.initForm(),
      rules: {
        dose: {
          doseBetween: ({
            unit: '@doseUnit',
            model: this.sim.simModel,
          })
        },
        interval: {
          intervalBetween: true,
          intervalDuration: ({
            duration: '@duration',
            durationUnit: '@durationUnit',
            intervalUnit: '@intervalUnit'
          })
        },
        duration: {
          durationBetween: ({
            unit: '@durationUnit'
          })
        },
      },
      customSim: null
    }
  },
  computed: {
    getSimulation() {
      return this.$store.getters.getSimulation
    },
    isFormValid() {
      return (this.form.amount !== null && this.form.duration !== null && this.form.interval !== null)
    }
  },
  methods: {
    initForm() {
      return {
        ...this.form,
        method: this.sim?.simDelivery?.method,
        amount: null,
        amountUnit: 'mg',
        duration: null,
        durationUnit: 'hours',
        interval: null,
        intervalUnit: 'hours',
        loading: false
      }
    },
    loadSim() {
      if (this.sim) {
        const sim = new Simulation()
        sim.simPatient = new Patient({...this.sim.simPatient})
        sim.simDrug = new Drug({...this.sim.simDrug})
        sim.simModel = this.sim.simModel
        sim.simPathology = new Pathogen({...this.sim.simPathology})
        sim.simTarget = new Target({...this.sim.simTarget})
        sim.simDoses = [...this.sim.simDoses]
        sim.simDelivery = new Delivery({...this.sim.simDelivery})
        sim.simAdministeredDoses = [...this.sim.simAdministeredDoses]
        sim.pkParams = [...this.sim.pkParams]
        this.customSim = sim
      }
    },
    start($event) {
      this.$store.dispatch('saveLoading', true)
      setTimeout(() => this.runSimulation($event), 30);
    },
    clear() {
      this.options = []
      this.form = this.initForm()
    },
    getValidationState(validator) {
      return !validator.pristine && validator.validated ? validator.valid : null;
    },
    runSimulation($event) {
      // clear current selected graph
      const currentSim = {...this.$store.getters.getSimulation, simSelected: null}
      this.$store.dispatch('saveSimulation', currentSim)

      this.$nextTick(() => {
        if (this.sim) {
          const custom = new Custom({...this.form})
          const plans = this.customSim.generateDosingOptions(custom)
          this.options = plans.map((s) => {
            if (this.sim.simDelivery.method === 'CONT') {
              return {
                Interval: s.recommendation.interval,
                intervalUnit: 'h',
                Dose: s.recommendation.maintenance,
                doseUnit: 'mg',
                Rate: s.recommendation.duration,
                rateUnit: s.recommendation.durationUnit,
                AUC: s.recommendation.auc,
                aucUnit: "mcg*hr/mL",
                SS: s.recommendation.ss,
                ssUnit: 'mcg/mL',
                plan: s,
                calculation: s.recommendation.calculation,
                _rowVariant: s._rowVariant
              }
            } else {
              return {
                Interval: s.recommendation.interval,
                intervalUnit: 'h',
                Dose: s.recommendation.maintenance,
                doseUnit: 'mg',
                AUC: s.recommendation.auc,
                aucUnit: "mcg*hr/mL",
                Peak: s.recommendation.peak,
                peakUnit: 'mcg/mL',
                Trough: s.recommendation.trough,
                troughUnit: 'mcg/mL',
                plan: s,
                Calculation: s.recommendation.calculation,
                _rowVariant: s._rowVariant
              }
            }
          })
          // this.$store.dispatch('saveCustom', this.options)
          this.$emit('switchTab', $event, 2)
        }
      })
    },
  }
}
</script>

<style scoped>
#custom form {
  display: flex;
  flex-direction: column;
  justify-items: center;
  margin: 0 28%;
}

.input-group {
  justify-content: start !important;
}

@media (max-width: 940px) {
  #custom form {
    margin: 0 5%;
  }
}

</style>