import {CALCULATION} from "./DoseRegime";

export class Dose {
    constructor(param: {
        duration: number,
        datetime: any,
        amount: number,
        amountUnit: string,
        _rowVariant: string,
        durationUnit: string,
        type: string,
        since?: number,
        sinceAlert?: string
    }) {
        this.id = Math.ceil(Math.random() * 1000000)
        this.datetime = param.datetime;
        this.amount = param.amount;
        this.amountUnit = param.amountUnit;
        this._rowVariant = param._rowVariant;
        this.duration = param.duration;
        this.durationUnit = param.durationUnit;
        this.type = param.type;
        this.since = param.since;
        this.sinceAlert = param.sinceAlert;
    }

    id: number | null | undefined;
    datetime: Date;
    type: string;
    amount: number;
    amountUnit: string;
    duration: number;
    durationUnit: string;
    _rowVariant: string | undefined;
    since: number | null | undefined;
    sinceAlert: string | null | undefined;
}