import {Patient} from "./Patient";
import {Dose} from "./Dose";
import {ConcTime} from "./ConcTime";
import {CALCULATION, DoseRegime} from "./DoseRegime";
import {DosePlan} from "./DosePlan";
import moment from "moment";
import {findMeasuredDoseNums} from "../plugins/bayes";
import {calculateCockcroftGault, drugClearance} from "./utils/clearance";
import {DoseTypes, PkType} from "./utils/constants";
import {Target} from "./Target";
import {Pathogen} from "./Pathogen";
import {PkParam} from "./PkParam";
import {DrugModel} from "./DrugModel";
import {Delivery} from "./Delivery";
import {Drug} from "./Drug";
import {
    calculateAuc,
    calculateConcTimeseries,
    calculatePeak,
    calculatePkByBayesian,
    calculatePkBySawchukZaske,
    calculateSteadyState,
    calculateTmic,
    calculateTrough
} from "./utils/calculations";

// import {DEBUG} from "../main.js"
const DEBUG = 1
import {Custom} from "./Custom";
import {
    calculateAlpha,
    calculateBeta,
    calculateCompartment,
    calculateConcentration2,
    calculateConcTimeseries2,
    calculatePkByBayesian2,
    calculateSteadyState2,
    calculateTmic2
} from "./utils/calculations_2compartment";
import {Pk} from "./Pk";


export class Simulation {
    id = 0;
    simPatient: Patient | null = null;
    simModel: DrugModel | null = null;
    simPathology: Pathogen | null = null;
    simDoses: Dose[] = [];
    pkParams: PkParam[] = [];
    simDosePlans: DosePlan[] = [];
    simSelected: DosePlan | null = null;
    simAdministeredDoses: ConcTime[] = [];
    simDelivery: Delivery | null = null;
    simTarget: Target | null = null;
    simDrug: Drug | null = null;

    // Data is loaded ready for simulation run
    isLoaded() {
        return this.simPatient && this.simModel && (this.getCreatinine().length > 0 || this.getCreatinineClearance().length > 0)
    }

    // Run generate functions in sequence
    generate() {
        if (this.isLoaded() && this.simModel && this.simModel.code) {
            // crcl based on patient serum Creatinine measurement or precalculated CLCR
            const crcl: number | null = this.getCreatinine().length > 0 ? calculateCockcroftGault(this) : this.getCreatinineClearance()[0]?.amount
            if (crcl) {
                // 1. use population model and patient CrCl to calculate Kel, Vd
                this.generatePk(crcl)
                // 2. if patient has measured doses, re-estimate Kel, Vd from plasma concentration/s
                if (this.hasMeasured()) {
                    if (this.simModel.getCompartment() === 1) {
                        this.generatePkFromMeasured()
                    } else if (this.simModel.getCompartment() === 2) {
                        this.generatePkFromMeasured2()
                    }
                }
                // 3. generate dose response for any administered doses - use last dose for simulation
                if (this.simDelivery?.method !== 'CONT') {
                    this.generateAdministeredDoses()
                }

                // 4. generate a range of dosing options with their pk params & conc over time
                // Use range of intervals and doses
                this.generateDosingOptions(null)
            }
        } else {
            console.error('Pk params data not loaded')
            return null
        }
    }

    // Step 1. Generate PK params for this patient based on model coefficients (population estimates) and patient CrCl
    generatePk(crcl: number | null) {
        const pkparams: PkParam[] = []
        if (crcl && this.simModel && this.simPatient) {
            const weight = this.simPatient.weightKg(false, false)
            if (weight) {
                // Patient pkparams
                const bsa = this.simPatient.bsa() ?? 1
                pkparams.push(new PkParam({
                    key: 'ideal',
                    label: 'BW Ideal',
                    value: this.simPatient.weightKg(true),
                    unit: 'kg',
                    type: PkType.PATIENT
                }))
                pkparams.push(new PkParam({
                    key: 'adjusted',
                    label: 'BW Adjusted',
                    value: this.simPatient.weightKg(true, true),
                    unit: 'kg',
                    type: PkType.PATIENT
                }))
                pkparams.push(new PkParam({
                    key: 'bmi',
                    label: 'BMI',
                    value: this.simPatient.bmi(),
                    unit: '',
                    type: PkType.PATIENT
                }))
                pkparams.push(new PkParam({
                    key: 'bsa',
                    label: 'BSA',
                    value: bsa,
                    unit: 'm2',
                    type: PkType.PATIENT
                }))
                pkparams.push(new PkParam({
                    key: 'crcl',
                    label: 'Creatinine CL',
                    value: crcl.toFixed(2),
                    unit: 'mL/min',
                    type: PkType.PATIENT
                }))

                // One or more compartment models
                if (this.simModel.getCompartment() === 1) {
                    const Vd: number = this.simModel.vd[0].coeff * weight
                    const Vd_sd: number = Vd * this.simModel.vd[0].sd
                    // Clearance of drug based on model coeff and patient crcl
                    const drugCl: number = drugClearance(this.simModel, crcl, bsa)
                    const drugCl_sd: number = drugCl * this.simModel.clearance[0].sd
                    const kel: number = drugCl / Vd
                    const halfLife: number = (0.693 * Vd) / drugCl
                    pkparams.push(new PkParam({
                        key: 'drugcl',
                        label: 'Drug CL',
                        value: drugCl.toFixed(2),
                        sd: drugCl_sd.toFixed(2),
                        unit: 'L/hr',
                        type: PkType.EMPIRIC
                    }))
                    pkparams.push(new PkParam({
                        key: 'vd',
                        label: 'Vd',
                        value: Vd.toFixed(2),
                        sd: Vd_sd.toFixed(2),
                        unit: 'L',
                        type: PkType.EMPIRIC
                    }))
                    pkparams.push(new PkParam({
                        key: 'kel',
                        label: 'Kel',
                        value: kel.toFixed(4),
                        unit: '/hr',
                        type: PkType.EMPIRIC
                    }))
                    pkparams.push(new PkParam({
                        key: 't12',
                        label: 'Half-life',
                        value: halfLife.toFixed(2),
                        unit: 'hrs',
                        type: PkType.EMPIRIC
                    }))
                    if (DEBUG) {
                        console.log(`generatePk: Population one-cpt drugCl=${drugCl}ml/hr vd=${Vd} kel=${kel}`)
                    }
                } else if (this.simModel.getCompartment() === 2 && this.simModel.vd.length === 2) {
                    const v1: Pk = this.simModel.vd[0]
                    const Vc: number = v1.coeff
                    const Vc_sd: number = v1.sd
                    const v2: Pk = this.simModel.vd[1]
                    const Vp: number = v2.coeff
                    const Vp_sd: number = v2.sd
                    const drugCl2 = this.simModel.clearance.filter((c) => c.type === 'central')[0]
                    const Q: number = this.simModel.clearance.filter((c) => c.type === 'intercompartmental')[0].coeff
                    const beta: number = calculateBeta(Q, Vc, Vp, drugCl2.coeff)
                    const alpha: number = calculateAlpha(Q, Vc, Vp, drugCl2.coeff, beta)
                    const A: number | null = calculateCompartment('A', Q, Vc, Vp, alpha, beta)
                    const B: number | null = calculateCompartment('B', Q, Vc, Vp, alpha, beta)
                    pkparams.push(new PkParam({
                        key: 'drugcl',
                        label: 'Drug CL',
                        value: drugCl2.coeff.toFixed(2),
                        sd: drugCl2.sd.toFixed(2),
                        unit: 'L/hr',
                        type: PkType.EMPIRIC
                    }))
                    pkparams.push(new PkParam({
                        key: 'Vc',
                        label: 'Vd (central)',
                        value: Vc.toFixed(2),
                        sd: Vc_sd.toFixed(2),
                        unit: 'L',
                        type: PkType.EMPIRIC
                    }))
                    pkparams.push(new PkParam({
                        key: 'Vp',
                        label: 'Vd (peripheral)',
                        value: Vp.toFixed(2),
                        sd: Vp_sd.toFixed(2),
                        unit: 'L',
                        type: PkType.EMPIRIC
                    }))
                    pkparams.push(new PkParam({
                        key: 'Q',
                        label: 'Q (intercompartmental)',
                        value: Q.toFixed(2),
                        unit: 'L/h',
                        type: PkType.EMPIRIC
                    }))
                    pkparams.push(new PkParam({
                        key: 'alpha',
                        label: 'Alpha (distribution)',
                        value: alpha.toFixed(2),
                        unit: '',
                        type: PkType.EMPIRIC
                    }))
                    pkparams.push(new PkParam({
                        key: 'beta',
                        label: 'Beta (elimination)',
                        value: beta.toFixed(2),
                        unit: '',
                        type: PkType.EMPIRIC
                    }))
                    pkparams.push(new PkParam({
                        key: 'A',
                        label: 'A (distribution)',
                        value: A?.toFixed(2),
                        unit: '',
                        type: PkType.EMPIRIC
                    }))
                    pkparams.push(new PkParam({
                        key: 'B',
                        label: 'B (elimination)',
                        value: B?.toFixed(2),
                        unit: '',
                        type: PkType.EMPIRIC
                    }))
                    if (DEBUG) {
                        console.log(`generatePk: Population 2-cpt drugCl=${drugCl2.coeff}ml/hr Vc=${Vc} Vp=${Vp} Q=${Q}`)
                    }
                } else {
                    throw Error('Unable to proceed with simulation - missing model info')
                }

                this.pkParams = pkparams
            } else {
                console.error('No patient CrCl to generate pkParams')
                throw Error('Unable to proceed with simulation - missing Crcl')
            }
        } else {
            console.error('No patient weight to generate pkParams')
            throw Error('Unable to proceed with simulation - missing weight')
        }

    }

    // For one compartment models
    // Step 1a. If patient has measured concentrations,
    // re-estimate cl, kel and vd
    // using Bayesian MCMC stepper
    // works best with 1 or more measurements in a single dose interval
    generatePkFromMeasured() {
        const doses: Dose[] | null = this.getDoses()
        const measured: Dose[] | null = this.getMeasured()
        const pop_clearance = this.getPk('drugcl')
        const pop_vd = this.getPk('vd')
        if (pop_clearance && pop_vd && doses.length > 0 && measured.length > 0) {
            const matched = this.measuredIntervals()
            // Sawchuk if 2 m in same interval for dose=1 or > 3 else Bayesian fit
            const pk: {
                kel: number;
                vd: number,
                cl: number,
                half: number
            } | null = this.hasSawchukInterval() ?
                calculatePkBySawchukZaske(this.simDelivery, this.simModel?.delivery, measured, matched, doses) :
                calculatePkByBayesian(pop_clearance, pop_vd, this.simDoses)
            if (pk) {
                if (DEBUG) {
                    console.log(`generatePkFromMeasured - optimised patient cl: ${pk.cl}ml/hr vd: ${pk.vd} kel: ${pk.kel}`)
                }

                this.pkParams.push(new PkParam({
                    key: 'drugcl_p',
                    label: 'Drug CL',
                    value: pk.cl,
                    unit: 'L/hr',
                    type: PkType.OPTIMISED
                }))
                this.pkParams.push(new PkParam({
                    key: 'vd_p',
                    label: 'Vd',
                    value: pk.vd,
                    unit: 'L',
                    type: PkType.OPTIMISED
                }))
                this.pkParams.push(new PkParam({
                    key: 'kel_p',
                    label: 'Kel',
                    value: pk.kel,
                    unit: '/hr',
                    type: PkType.OPTIMISED
                }))
                this.pkParams.push(new PkParam({
                    key: 't12_p',
                    label: 'Half-life',
                    value: pk.half,
                    unit: 'hrs',
                    type: PkType.OPTIMISED
                }))
            }

        }
    }

    // For 2 compartment models
    // If patient has measured concentrations - re-estimate A, alpha, B, beta - Bayesian only
    generatePkFromMeasured2() {
        const sim = this
        const doses: Dose[] | null = sim.getDoses()
        const measured: Dose[] | null = sim.getMeasured()
        const pop_vc = sim.getPk('Vc')
        const pop_vp = sim.getPk('Vp')
        const pop_q = sim.getPkValue('Q')
        const pop_cl = sim.getPkValue('drugcl')
        if (doses.length > 0 && measured.length > 0 && pop_vc && pop_vp && pop_q && pop_cl) {
            const pk: {
                vc: number;
                vp: number,
                A: number,
                alpha: number,
                B: number,
                beta: number
            } | null = calculatePkByBayesian2(pop_vc, pop_vp, pop_cl, pop_q, sim.simDoses)
            if (pk) {
                if (DEBUG) {
                    console.log(`generatePkFromMeasured2compartment optimised vc: ${pk.vc} vp: ${pk.vp} A: ${pk.A} alpha: ${pk.alpha} B: ${pk.B} beta: ${pk.beta}`)
                }

                this.pkParams.push(new PkParam({
                    key: 'Vc_p',
                    label: 'Vd (central)',
                    value: pk.vc,
                    unit: 'L',
                    type: PkType.OPTIMISED
                }))
                this.pkParams.push(new PkParam({
                    key: 'Vp_p',
                    label: 'Vd (peripheral)',
                    value: pk.vp,
                    unit: 'L',
                    type: PkType.OPTIMISED
                }))
                this.pkParams.push(new PkParam({
                    key: 'A_p',
                    label: 'A (distribution)',
                    value: pk.A,
                    unit: '',
                    type: PkType.OPTIMISED
                }))
                this.pkParams.push(new PkParam({
                    key: 'alpha_p',
                    label: 'Alpha (distribution)',
                    value: pk.alpha,
                    unit: '',
                    type: PkType.OPTIMISED
                }))
                this.pkParams.push(new PkParam({
                    key: 'B_p',
                    label: 'B (elimination)',
                    value: pk.B,
                    unit: '',
                    type: PkType.OPTIMISED
                }))
                this.pkParams.push(new PkParam({
                    key: 'beta_p',
                    label: 'Beta (elimination)',
                    value: pk.beta,
                    unit: '',
                    type: PkType.OPTIMISED
                }))
            }
        }
    }

    // Step 2. Generate conc series for administered doses
    generateAdministeredDoses() {
        const givenDoses: Dose[] = this.getDoses()
        const firstDose: Dose | null = givenDoses[0] ?? null
        let doseArray: ConcTime[] = []
        if (this.isLoaded() && this.pkParams.length > 0 && firstDose) {
            const compartment: number = this.simModel ? this.simModel.getCompartment() : 1
            const intervalString = 'administered'
            const calculationTypes = this.getMeasured() && this.getMeasured().length > 0 ? [CALCULATION.POPULATION, CALCULATION.PATIENT] : [CALCULATION.POPULATION]

            calculationTypes.forEach((calculation) => {
                if (compartment === 1) {
                    const pk_type = calculation === CALCULATION.POPULATION ? '' : '_p'
                    const vd: number | null = this.getPkValue('vd' + pk_type)
                    const kel: number | null = this.getPkValue('kel' + pk_type)
                    if (kel && vd) {
                        if (givenDoses.length === 1) {
                            let initialTime = new Date(firstDose.datetime)
                            const concentration = (firstDose.amount / vd) * Math.exp(-kel * firstDose.duration)
                            const intervalArray = calculateConcTimeseries(
                                0,
                                intervalString,
                                vd, kel,
                                initialTime,
                                0,
                                concentration,
                                0,
                                firstDose.duration,
                                firstDose.durationUnit,
                                calculation)
                            doseArray = doseArray.concat(intervalArray)
                            if (DEBUG) {
                                console.log('-----------ADMIN DOSES---------------')
                                console.log(`${calculation}: Dose: ${firstDose.amount}`)
                                console.log(`${calculation}: Interval: 0`)
                                console.log(`${calculation}: Infusion=${firstDose.duration} ${firstDose.durationUnit}`)
                                console.log(`${calculation}: InitialConc (c0)= 0 mg/L`)
                                console.log(`${calculation}: LoadingConc=${concentration} mg/L`)
                                console.log(`${calculation}: MaintenanceConc=0 mg/L`)
                            }
                        } else {
                            for (let i: number = 0; i < givenDoses.length - 1; i++) {
                                const dose = givenDoses[i]
                                // @ts-ignore
                                const interval = (givenDoses[i + 1].datetime - givenDoses[i].datetime) / (1000 * 60 * 60)
                                // next dose concentration - add if last dose
                                const maintenanceConcentration = i < givenDoses.length - 2 ? 0 : givenDoses[i + 1].amount / vd
                                // dose concentration at end of infusion
                                const loadingConcentration = (dose.amount / vd) * Math.exp(-kel * dose.duration)
                                const initialConcentration = i <= 0 ? 0 : doseArray[doseArray.length - 1].concentration + loadingConcentration
                                // @ts-ignore
                                const initialTime = i <= 0 ? new Date(dose.datetime) : moment(dose.datetime).add(dose.duration, dose.durationUnit).toDate()
                                const intervalArray: ConcTime[] = calculateConcTimeseries(
                                    interval,
                                    intervalString,
                                    vd, kel,
                                    initialTime,
                                    initialConcentration,
                                    loadingConcentration,
                                    maintenanceConcentration,
                                    dose.duration,
                                    dose.durationUnit,
                                    calculation)
                                doseArray = doseArray.concat(intervalArray)
                                if (DEBUG) {
                                    console.log('-----------ADMIN DOSES---------------')
                                    console.log(`${calculation}: Dose: ${dose.amount}`)
                                    console.log(`${calculation}: Interval: ${interval}`)
                                    console.log(`${calculation}: Infusion=${dose.duration} ${dose.durationUnit}`)
                                    console.log(`${calculation}: InitialConc (c0)=${initialConcentration} mg/L`)
                                    console.log(`${calculation}: LoadingConc=${loadingConcentration} mg/L`)
                                    console.log(`${calculation}: MaintenanceConc=${maintenanceConcentration} mg/L`)
                                }
                            }
                        }
                    }
                } else if (compartment === 2) {
                    const pk_type = calculation === CALCULATION.POPULATION ? '' : '_p'
                    const pk_alpha: number | null = this.getPkValue('alpha' + pk_type)
                    const pk_beta: number | null = this.getPkValue('beta' + pk_type)
                    const pk_A: number | null = this.getPkValue('A' + pk_type)
                    const pk_B: number | null = this.getPkValue('B' + pk_type)

                    if (pk_alpha && pk_A && pk_beta && pk_B) {
                        if (givenDoses.length === 1) {
                            let initialTime = new Date(firstDose.datetime)
                            const intervalArray = calculateConcTimeseries2(
                                0,
                                intervalString,
                                firstDose.amount,
                                initialTime,
                                0,
                                firstDose.duration,
                                firstDose.durationUnit,
                                pk_A, pk_B, pk_alpha, pk_beta,
                                calculation)
                            doseArray = doseArray.concat(intervalArray)
                            if (DEBUG) {
                                console.log('-----------ADMIN DOSES---------------')
                                console.log(`${calculation}: Dose: ${firstDose.amount}`)
                                console.log(`${calculation}: Interval: 0`)
                                console.log(`${calculation}: c0=0 mg/L`)
                                console.log(`${calculation}: PARAMS: a=${pk_A} b=${pk_B} alpha=${pk_alpha} beta=${pk_beta}`)
                            }
                        } else {
                            for (let i: number = 0; i < givenDoses.length - 1; i++) {
                                const dose = givenDoses[i]
                                // @ts-ignore
                                const interval = (givenDoses[i + 1].datetime - givenDoses[i].datetime) / (1000 * 60 * 60)
                                const initialConcentration = i <= 0 ? 0 : doseArray[doseArray.length - 1].concentration
                                // @ts-ignore
                                const initialTime = i <= 0 ? new Date(dose.datetime) : moment(dose.datetime).add(dose.duration, dose.durationUnit).toDate()
                                const intervalArray: ConcTime[] = calculateConcTimeseries2(
                                    interval,
                                    intervalString,
                                    dose.amount,
                                    initialTime,
                                    initialConcentration,
                                    dose.duration,
                                    dose.durationUnit,
                                    pk_A, pk_B, pk_alpha, pk_beta,
                                    calculation)
                                doseArray = doseArray.concat(intervalArray)
                                if (DEBUG) {
                                    console.log('-----------ADMIN DOSES---------------')
                                    console.log(`${calculation}: Dose: ${dose.amount}`)
                                    console.log(`${calculation}: Interval: ${interval}`)
                                    console.log(`${calculation}: c0=${initialConcentration} mg/L`)
                                    console.log(`${calculation}: PARAMS: a=${pk_A} b=${pk_B} alpha=${pk_alpha} beta=${pk_beta}`)
                                }
                            }
                        }
                    }
                }
                console.log(`${doseArray.length} administeredDoses for ${calculation} added`)
                this.simAdministeredDoses = doseArray
            })
        }
    }

    // Step 3. Generate Dosing options
    // For each interval, generate a recommendation and concTimeSeries curve
    // @params custom: Custom form fields
    // @return Array of DosePlans (saved to sim.simDosePlans)
    generateDosingOptions(custom: Custom | null | undefined): DosePlan[] {
        const options: DosePlan[] = []
        const weight = this.simPatient?.weightKg() ?? null
        const intervals: number[] = custom ? [custom.interval] : this.simDrug?.getIntervalRange('IV') ?? []
        const maintenanceDoses: number[] = custom ? [custom.amount] : this.simDrug?.getAmountRange('IV') ?? []
        this.simSelected = null;
        // const mic = this.getMic()
        if (this.simModel && weight && intervals.length > 0) {
            const infusionDuration: number = custom ? custom.duration : this.getDuration()
            const infusionDurationUnit: string = custom ? custom.durationUnit : this.getDurationUnit()
            const infusionHrs: number = infusionDurationUnit === 'hours' ? infusionDuration * 1 : infusionDuration / 60

            const calculationTypes = this.getMeasured() && this.getMeasured().length > 0 ? [CALCULATION.POPULATION, CALCULATION.PATIENT] : [CALCULATION.POPULATION]
            if (this.simModel.getCompartment() === 1) {
                // Single compartment model - Population, Patient
                calculationTypes.forEach((calculationType) => {
                    if (this.hasCalculationType(calculationType, 1)) {
                        // @ts-ignore
                        const givenDoses: ConcTime[] = this.simAdministeredDoses.filter((d) => d.calculation === calculationType)
                        const lastDose: ConcTime | null = givenDoses.length > 0 ? givenDoses[givenDoses.length - 1] : null
                        const pk_type: string = calculationType === CALCULATION.POPULATION ? '' : '_p'
                        const vd: number | null = this.getPkValue('vd' + pk_type)
                        const kel: number | null = this.getPkValue('kel' + pk_type)
                        const cl: number | null = this.getPkValue('drugcl' + pk_type)

                        if (vd && kel && cl) {
                            // initial concentration
                            const initialConcentration: number = lastDose ? lastDose.concentration * 1 : 0
                            const initialTime: Date = lastDose ? lastDose.time : new Date()

                            // Loop through each preset maintenance dose
                            maintenanceDoses.forEach((mDose: number) => {
                                let loadingDose: number | null | undefined = !lastDose ? this.estimateDose(weight, true, mDose) : null
                                // Override for custom
                                if (loadingDose && custom && !custom.loading) {
                                    loadingDose = null
                                }
                                const maintenanceConcentration: number = (mDose / vd) * Math.exp(-kel * infusionDuration)
                                const loadingConcentration: number | null = loadingDose ? (loadingDose / vd) * Math.exp(-kel * infusionHrs) : null

                                intervals.forEach((interval) => {
                                    if (DEBUG) {
                                        console.log('----------SIMULATED--------------')
                                        console.log(`${calculationType}: Dose: ${mDose}`)
                                        console.log(`${calculationType}: Interval: ${interval}`)
                                        console.log(`${calculationType}: Infusion=${infusionHrs} hrs`)
                                        console.log(`${calculationType}: InitialConc (c0)=${initialConcentration} mg/L`)
                                        console.log(`${calculationType}: LoadingConc=${loadingConcentration} mg/L`)
                                        console.log(`${calculationType}: MaintenanceConc=${maintenanceConcentration} mg/L`)
                                    }
                                    const recommendation: DoseRegime | null = this.estimateDoseRegime(
                                        calculationType,
                                        initialConcentration,
                                        vd, kel, cl,
                                        loadingDose,
                                        mDose,
                                        interval)

                                    const intervalString: string = `q${interval}h`
                                    const concTimeSeries: ConcTime[] = calculateConcTimeseries(
                                        interval, intervalString,
                                        vd, kel,
                                        initialTime,
                                        initialConcentration,
                                        loadingConcentration,
                                        maintenanceConcentration,
                                        infusionDuration,
                                        infusionDurationUnit,
                                        calculationType,
                                        this.simDelivery?.daysToDisplay)
                                    const highlight = this.getHighlight(recommendation)
                                    options.push(new DosePlan(recommendation, concTimeSeries, highlight))
                                })
                            })
                            if (custom) {
                                return options
                            } else {
                                this.simDosePlans = options
                                console.log(`${options.length} option plans for ${calculationType} added`)
                                // set first in range as default
                                // this.simSelected = options.filter((d) => d._rowVariant === 'success')[0] ?? options[0]
                            }
                        } else {
                            console.error('Pk params missing')
                        }
                    }
                })
            } else if (this.simModel.getCompartment() === 2) {
                // Create plans for two compartment model
                calculationTypes.forEach((calculationType) => {
                    if (this.hasCalculationType(calculationType, 2)) {
                        const givenDoses = this.simAdministeredDoses.filter((d) => d.calculation === calculationType)
                        const lastDose = givenDoses.length > 0 ? givenDoses[givenDoses.length - 1] : null

                        const initialTime: Date = lastDose ? lastDose.time : new Date()
                        const pk_type = calculationType === CALCULATION.POPULATION ? '' : '_p'
                        const vc = this.getPkValue('Vc' + pk_type)
                        const vp = this.getPkValue('Vp' + pk_type)
                        const alpha = this.getPkValue('alpha' + pk_type)
                        const beta = this.getPkValue('beta' + pk_type)
                        const a = this.getPkValue('A' + pk_type)
                        const b = this.getPkValue('B' + pk_type)
                        const initialConcentration: number = lastDose ? lastDose.concentration * 1 : 0
                        const cl = this.getPkValue('drugcl')
                        if (vc && vp && cl && a && b && alpha && beta) {
                            maintenanceDoses.forEach((mDose: number) => {
                                intervals.forEach((interval) => {
                                    const recommendation: DoseRegime | null = this.estimateDoseRegime2(
                                        calculationType,
                                        mDose,
                                        vc, vp, cl, alpha, beta, a, b,
                                        interval)

                                    const intervalString: string = `q${interval}h`
                                    const concTimeSeries: ConcTime[] = calculateConcTimeseries2(
                                        interval,
                                        intervalString,
                                        mDose,
                                        initialTime,
                                        initialConcentration,
                                        infusionDuration,
                                        infusionDurationUnit,
                                        a, b, alpha, beta,
                                        calculationType)
                                    const highlight = this.getHighlight(recommendation)
                                    options.push(new DosePlan(recommendation, concTimeSeries, highlight))
                                    if (DEBUG) {
                                        console.log('----------SIMULATED--------------')
                                        console.log(`${calculationType}: Dose: ${mDose}`)
                                        console.log(`${calculationType}: Interval: ${interval}`)
                                        console.log(`${calculationType}: c0=${initialConcentration} mg/L`)
                                        console.log(`${calculationType}: PARAMS: a=${a} b=${b} alpha=${alpha} beta=${beta}`)
                                    }
                                })
                            })
                            if (custom) {
                                return options
                            } else {
                                this.simDosePlans = options
                                console.log(`${options.length} option plans for ${calculationType} added`)
                                // set first in range as default
                                // this.simSelected = options.filter((d) => d._rowVariant === 'success')[0] ?? options[0]
                            }
                        }
                    }
                })
            }
        }
        return options
    }

    // **** Utility methods *** //
    hasMeasured() {
        return this.simDoses.filter((d) => d.type === 'CONC').length > 0
    }

    getDoses() {
        // @ts-ignore
        return this.simDoses.filter((dose: Dose) => dose.type === DoseTypes.DOSE).sort((a: Dose, b: Dose) => new Date(a.datetime) - new Date(b.datetime))
    }

    getMeasured() {
        // @ts-ignore
        return this.simDoses.filter((dose: Dose) => dose.type === DoseTypes.CONC).sort((a: Dose, b: Dose) => new Date(a.datetime) - new Date(b.datetime))
    }

    // Sort by most recent first
    // Gets Serum creatinine value if present in simDoses
    // @return [value,...] or []
    getCreatinine() {
        // @ts-ignore
        return this.simDoses.filter((dose: Dose) => dose.type === DoseTypes.SECR).sort((a: Dose, b: Dose) => new Date(b.datetime) - new Date(a.datetime))
    }

    // Sort by most recent first
    // Gets creatinine clearance if present in simDoses (Urine 24 h collection)
    // @return [value,...] or []
    getCreatinineClearance() {
        // @ts-ignore
        return this.simDoses.filter((dose: Dose) => dose.type === DoseTypes.CLCR).sort((a: Dose, b: Dose) => new Date(b.datetime) - new Date(a.datetime))
    }

    // Use duration provided from Delivery or default
    getDuration() {
        return this.simDelivery?.duration ? this.simDelivery.duration * 1 : 1.00
    }

    getDurationUnit() {
        return this.simDelivery?.duration ? this.simDelivery.durationUnit : "hours"
    }

    getMic() {
        return this.simPathology?.mic ?? 1
    }


    //***** Calculation Methods ******//

    // estimate dose from model mg per kg
    // @params weight: actual BW
    // @params loading: provide loading dose if true otherwise provide maintenance dose
    // @returns estimated dose (if greater than dose) or null
    estimateDose(weight: number, loading: boolean = true, dose: number | null = null): number | null {
        const estimatedDose: number | null = loading && this.simModel?.default?.loadingDoseMgPerKg ? this.simModel.default.loadingDoseMgPerKg * weight :
            !loading && this.simModel?.default?.maintenanceDoseMgPerKg ? this.simModel.default.maintenanceDoseMgPerKg * weight : null
        if (estimatedDose && dose) {
            return dose < estimatedDose ? estimatedDose : null
        }
        return estimatedDose
    }

    // DoseRegime for single compartment models
    // Calculate Peak, Trough, AUC, ss for given dose, Kel and Drugcl
    // If first, maintenance and interval are undefined, will also estimate loadingDose, maintenanceDose, duration of infusion and interval
    // @params c0: initial plasma concentration (mg/ml)
    // @params vd: Vol distribution
    // @params kel: Elimination coeff
    // @params cl: Drug clearance (ml/min)
    // @params first: dose (may be loading) (mg)
    // @params maintenance: dose (mg)
    // @params interval: dose interval (hrs)
    estimateDoseRegime(calculationType: string, c0: number, vd: number, kel: number, cl: number, first: number | null | undefined, maintenance: number, interval: number | undefined) {
        let recommendation: DoseRegime | null = null
        if (this.isLoaded()) {
            const crcl = this.getPk('crcl')
            const weight = this.simPatient?.weightKg()
            if (crcl && weight) {
                const estimateLoadingDose: number | null | undefined = first;
                const estimateMaintenanceDose: number = maintenance;
                const estimateInterval = interval ?? this.simModel?.default.interval(crcl) ?? 8;
                const duration = this.getDuration()
                const durationUnit = this.getDurationUnit()
                const infusionHrs = durationUnit === 'hours' ? duration * 1 : duration / 60
                const mic = this.getMic()
                const estimatePeak = calculatePeak(kel, estimateInterval, infusionHrs, vd, estimateMaintenanceDose)
                const estimateTrough = calculateTrough(estimatePeak, kel, estimateInterval, infusionHrs)
                const estimateAuc = calculateAuc(estimatePeak, estimateTrough, estimateInterval, infusionHrs, mic)
                const estimateSS = estimateMaintenanceDose ? calculateSteadyState(estimateMaintenanceDose, cl, estimateInterval) : null
                const tMic = calculateTmic(estimatePeak, mic, kel, infusionHrs)
                const estimateTmic_percent = tMic && tMic < estimateInterval ? (tMic / estimateInterval) * 100 : 100
                recommendation = new DoseRegime({
                    calculation: calculationType,
                    peak: estimatePeak.toFixed(2),
                    trough: estimateTrough.toFixed(2),
                    loading: estimateLoadingDose ? estimateLoadingDose.toFixed(0) : 'x',
                    maintenance: estimateMaintenanceDose ? estimateMaintenanceDose.toFixed(0) : 'x',
                    interval: estimateInterval.toFixed(0),
                    duration: duration.toFixed(2),
                    durationUnit: durationUnit,
                    auc: estimateAuc.toFixed(2),
                    ss: estimateSS ? estimateSS.toFixed(2) : 'x',
                    tmic: tMic ? estimateTmic_percent.toFixed(2) : 'x',
                    targetMethod: this.simTarget?.type ?? undefined,
                    deliveryMethod: this.simDelivery?.method ?? undefined
                })
            }
        } else {
            console.error('Error: simulation data is not loaded')
        }
        return recommendation
    }


    // DoseRegime for 2 compartment models
    // Calculate Peak, T>MIC, ss for given dose
    // If first, maintenance and interval are undefined, will also estimate loadingDose, maintenanceDose, duration of infusion and interval
    // @params calculationType: Empiric or Optimised - for retrieving appropriate pk values
    // @params dose: applied dose (mg)
    // @params Vc: Vol distribution (central cpt)
    // @params Vp: Vol distribution (peripheral cpt)
    // @params cl: Drug clearance (central) (ml/min)
    // @params alpha
    // @params beta
    // @params A
    // @params B
    // @params interval: dose interval (hrs)
    // @params mic : MIC concentration (mg/L)
    // @params infusion: duration of infusion (h)
    estimateDoseRegime2(calculationType: string, dose: number, Vc: number, Vp: number, cl: number, alpha: number, beta: number, A: number, B: number, interval: number | undefined) {
        let recommendation: DoseRegime | null = null
        if (this.isLoaded()) {
            const crcl = this.getPk('crcl')
            const weight = this.simPatient?.weightKg()
            if (crcl && weight) {
                const estimateInterval = interval ?? this.simModel?.default.interval(crcl) ?? 8;
                const mic = this.getMic()
                const duration = this.getDuration()
                const durationUnit = this.getDurationUnit()
                const infusionHrs = durationUnit === 'hours' ? duration * 1 : duration / 60
                // console.log(`peak from dose=${dose}`)
                const cPeak = calculateConcentration2(0, dose, 0, infusionHrs, A, B, alpha, beta) //TODO this doesn't seem right
                const estimateSS = calculateSteadyState2(dose, A, alpha, B, beta, estimateInterval, infusionHrs) //TODO this seems too low
                const tMic = mic ? calculateTmic2(cPeak, mic, alpha, beta, infusionHrs) : null //TODO need to fix calculus :(
                const estimateTmic_percent = tMic && tMic < estimateInterval ? (tMic / estimateInterval) * 100 : 100
                recommendation = new DoseRegime({
                    calculation: calculationType,
                    peak: cPeak.toFixed(2),
                    trough: 'x',
                    loading: 'x',
                    maintenance: dose.toFixed(0),
                    interval: estimateInterval.toFixed(0),
                    duration: duration.toFixed(2),
                    durationUnit: durationUnit,
                    auc: 'x',
                    ss: estimateSS ? estimateSS.toFixed(2) : 'x',
                    tmic: tMic ? estimateTmic_percent.toFixed(2) : 'x',
                    targetMethod: this.simTarget?.type ?? undefined,
                    deliveryMethod: this.simDelivery?.method ?? undefined
                })
            }
        } else {
            console.error('Error: simulation data is not loaded')
        }
        return recommendation
    }


    hasCalculationType(calculationType: string, compartment: number = 1) {
        const testPk = compartment === 1 ? 'vd' : 'alpha'
        const vd = calculationType === CALCULATION.POPULATION ? this.getPk(testPk) : this.getPk(testPk + '_p')
        return vd !== null;
    }

    // Check if measured levels are in same dose interval and it is the first dose or at steady state (> 3 doses)
    // @return object {first: count, ss:
    private measuredIntervals() {
        const doses: Dose[] | null = this.getDoses()
        const measured: Dose[] | null = this.getMeasured()
        const measuredDoseNums = findMeasuredDoseNums(this.simDoses)
        const matched: Record<number, number[]> = {}
        if (doses.length > 0 && measured.length > 0) {
            for (let i = 0; i < measured.length; i++) {
                const mDose = doses[measuredDoseNums[i]]
                if (mDose) {
                    const idxDose = doses.map((d) => d.id).indexOf(mDose.id)
                    if (matched[idxDose]) {
                        matched[idxDose].push(i)
                    } else {
                        matched[idxDose] = [i]
                    }
                }
            }
        }
        if (DEBUG) {
            console.log(`Doses with measurements: ${Object.keys(matched)}`)
        }
        return matched;
    }

    private hasSawchukInterval() {
        let rtn = false
        const matched = this.measuredIntervals()
        Object.keys(matched).forEach((m) => {
            if (parseInt(m) == 0 || parseInt(m) > 2) {
                // @ts-ignore
                rtn = matched[m].length > 1
                if (rtn) {
                    return
                }
            }
        })
        if (DEBUG) {
            console.log(`use sawchuk: ${rtn}`)
        }
        return rtn
    }

    getPk(key: string): PkParam | null {
        return this.pkParams.find((p) => p.key === key) ?? null
    }

    getPkValue(key: string): number | null {
        const val = this.getPk(key)
        if (val && val.value) {
            return parseFloat(<string>val.value);
        }
        return null
    }

    getHighlight(recommendation: DoseRegime | null): string {
        let rtn = ''
        const target = this.simTarget ?? this.simDrug?.target.find((ta: any) => ta.type.toLowerCase() === 'auc/mic') // default to AUC/MIC
        if (target && target.type && recommendation) {
            let check = null
            if (target.type.toLowerCase() === 'auc/mic') {
                check = (typeof recommendation.auc === "string") ? parseFloat(recommendation.auc) : recommendation.auc
            } else if (target.type.toLowerCase() === 'peak') {
                check = (typeof recommendation.peak === "string") ? parseFloat(recommendation.peak) : recommendation.peak
            } else if (target.type.toLowerCase() === 'trough') {
                check = (typeof recommendation.trough === "string") ? parseFloat(recommendation.trough) : recommendation.trough
            } else if (target.type.toLowerCase() === 'steady state') {
                check = (typeof recommendation.ss === "string") ? parseFloat(recommendation.ss) : recommendation.ss
            } else if (target.type.toLowerCase() === 'ft>mic') {
                check = (typeof recommendation.tmic === "string") ? parseFloat(recommendation.tmic) : recommendation.tmic
            }
            // @ts-ignore
            rtn = check && check >= target.min && check <= target.max ? 'success' : ''
        }
        return rtn

    }

    private getRangeOfDoses() {
        let drange: number[] = []
        if (this.simModel) {
            const min = this.simModel.default.minDose
            const max = this.simModel.default.maxDose
            const step = this.simModel.default.incrementDose
            if (min && max && step) {
                for (let i = min; i < max; i = i + step) {
                    drange.push(i)
                }
            }
        }
        return drange
    }


}