<template>
  <div>
    <!-- modals -->
    <b-modal id="import"
             ref="modal"
             title="Import doses from a CSV file"
             @ok="importDoses"
    >
      <b-form ref="form">
        <b-form-group>
          <input type="file" accept=".csv" @change="handleFileUpload( $event )"/>
        </b-form-group>
        <hr/>
        <b-form-group>
          <label class="form-label">Which source was the CSV file exported from?</label>
          <b-form-radio-group v-model="importSource">
            <b-form-radio v-for="(item, key) in sources"
                          name="importSource"
                          :key="key"
                          :value="key"
            ><span style="margin-left:10px;">{{ item }}</span>
            </b-form-radio>
          </b-form-radio-group>
        </b-form-group>
        <br/>
        <b-form-group>
          <label class="form-label">Which format is the date and time provided in?</label>
          <b-form-radio-group v-model="importFormat">
            <b-form-radio v-for="(item, key) in formats"
                          name="importFormat"
                          :key="key"
                          :value="key"
            ><span style="margin-left:10px;">{{ item }}</span>
            </b-form-radio>
          </b-form-radio-group>
        </b-form-group>
      </b-form>
      <div class="overflow-auto" v-if="parsed">
        <h3>Preview</h3>
        <table style="width: 100%;">
          <thead>
          <tr>
            <th v-for="(header, key) in content.meta.fields"
                v-bind:key="'header-'+key">
              {{ header }}
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(row, rowKey) in content.data.slice(0,12)"
              v-bind:key="'row-'+rowKey">
            <td v-for="(column, columnKey) in content.meta.fields"
                v-bind:key="'row-'+rowKey+'-column-'+columnKey">
              <input v-model="content.data[rowKey][column]"/>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </b-modal>
    <!-- content -->
    <b-card class="small" v-if="loaded">
      <b-card-title>Applied doses and measurements</b-card-title>
      <b-card-body v-if="items.length > 0">
        <b-table
            id="fb-table"
            striped
            fixed
            small
            responsive="sm"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :items="items"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            label-sort-asc=""
            label-sort-desc=""
            label-sort-clear=""
            @row-clicked="onRowClicked">
          <template #cell(datetime)="data">
            <b-input-group v-if="editCell.row === data.index && 'datetime' === editCell.field">
              <b-form-input
                  id="editCell"
                  v-model="editCell.newValue"
                  type="text"
                  autocomplete="off"
                  :state="editCell.valid"
                  @input="onInput"
                  @keyup.escape="onEscape"
                  @keyup.enter="onEnter"
              ></b-form-input>
            </b-input-group>
            <span v-else @click="editCellHandler(data.index, 'datetime')">{{formatDate(data.item.datetime)}}</span>
          </template>
          <template #cell(actions)="{item}">
            <span class="icon-span">
              <b-icon-trash width="1.6em" height="1.6em" title="Delete"
                            @click="deleteFromTable(item)"></b-icon-trash>
            </span>
          </template>
          <template #cell(amount)="data">
            <b-input-group v-if="editCell.row === data.index && 'amount' === editCell.field">
              <b-form-input
                  id="editCell"
                  v-model="editCell.newValue"
                  type="number"
                  :number="true"
                  :state="editCell.valid"
                  class="input-with-unit"
                  placeholder="Amount"
                  aria-describedby="input-1-live-feedback"
                  @input="onInput"
                  @keyup.escape="onEscape"
                  @keyup.enter="onEnter"
              />
              <b-input-group-append>
                <b-form-select
                    id="editCell" ref="doseUnit"
                    v-model="editCell.newUnit"
                    class="unit-dropdown"
                    value-field="value"
                    html-field="text"
                    :options="unitsForType(data.item.type)"
                    :clearable="false"
                    @input="onInput"
                    @keyup.escape="onEscape"
                    @keyup.enter="onEnter"
                >
                </b-form-select>
              </b-input-group-append>
            </b-input-group>
            <span v-else @click="editCellHandler(data.index, 'amount')">{{ data.item.amount }} <span v-html="displaySIUnits(data.item.amountUnit)"></span></span>
          </template>
          <template #cell(duration)="data">
            <b-input-group v-if="editCell.row === data.index && 'duration' === editCell.field">
              <b-form-input
                  id="editCell"
                  v-model="editCell.newValue"
                  type="number"
                  :number="true"
                  class="input-with-unit"
                  :state="editCell.valid"
                  aria-describedby="input-2-live-feedback"
                  @input="onInput"
                  @keyup.escape="onEscape"
                  @keyup.enter="onEnter"
              />
              <b-input-group-append>
                <b-form-select
                    id="editCell" ref="durationUnit"
                    v-model="editCell.newUnit"
                    class="unit-dropdown"
                    :options="units.duration"
                    :clearable="false"
                    @input="onInput"
                    @keyup.escape="onEscape"
                    @keyup.enter="onEnter"
                >
                </b-form-select>
              </b-input-group-append>
            </b-input-group>
            <span v-else @click="editCellHandler(data.index, 'duration')">{{ data.item.duration }} <span v-html="displaySIUnits(data.item.durationUnit)"></span></span>
          </template>
          <template #cell(since)="{item}"><span v-if="item.since" title="hrs since previous dose">{{ item.since }} h</span> <span v-if="item.sinceAlert">
            <b-icon-exclamation-triangle class="icon-red" :title="item.sinceAlert"></b-icon-exclamation-triangle></span>
          </template>
        </b-table>
        <h4><span class="badge bg-danger" v-html="this.editCell.message"></span></h4>
        <KeepAlive>
          <b-pagination
              v-model="currentPage"
              :total-rows="items.length"
              :per-page="perPage"
              aria-controls="fb-table"
          ></b-pagination>
        </KeepAlive>
      </b-card-body>
      <b-card-footer class="d-flex flex-row-reverse">
        <b-button class="m-2" @click="importModal">IMPORT</b-button>
        <download-excel :name="exportFilename"
                        type="csv"
                        :data="items"
                        :fields="export_fields"
                        :escapeCsv="false" v-if="items.length > 0">
          <b-button class="m-2">EXPORT</b-button>
        </download-excel>
      </b-card-footer>
    </b-card>
    <div v-else>
      <p>Select a drug model then enter patient doses or select from saved patients</p>
    </div>
  </div>
</template>
<script>
import Papa from 'papaparse';
import {DATEFORMAT, FILESOURCE, importDoseFromRow} from "@/components/services/common";
import moment from "moment";
import {Dose} from "@/models/Dose";
import {displaySIUnits} from "@/models/utils/constants";
import {units} from "../../models/utils/constants";
import DateTimeInput from "@/components/inputs/DateTimeInput.vue";
import {validations} from "../../models/utils/validations";

export default {
  name: "DosesTable",
  components: {DateTimeInput},
  props: ["model"],
  data() {
    return {
      sortBy: 'dose_datetime',
      sortDesc: false,
      perPage: 15,
      currentPage: 1,
      file: '',
      content: [],
      parsed: false,
      formats: DATEFORMAT,
      importFormat: "DMY",
      sources: FILESOURCE,
      importSource: "DOSING",
      savedPatient: null,
      fields: [
        {
          key: 'datetime',
          label: 'Date & time',
          sortable: true,
          formatter: "formatDate"
        },
        {
          key: 'type',
          sortable: true
        },
        {
          key: 'amount',
          sortable: true,
        },
        {
          key: 'duration',
          sortable: true,
        },
        {
          key: 'since',
          sortable: true,
        },
        {
          key: 'actions',
          sortable: false
        }
      ],
      export_fields: {
        "Type": "type",
        "Time": {
          field: "datetime",
          callback: (value) => {
            return moment(value).format(DATEFORMAT.YMDA)
          }
        },
        "Value": "amount",
        "Value_unit": "amountUnit",
        "Infusion": "duration",
        "Infusion_unit": "durationUnit"
      },
      editCell: {
        row: -1,
        field: null,
        newValue: null,
        newUnit: null,
        valid: null,
        message: ''
      }
    }
  },
  computed: {
    units() {
      return units
    },
    items() {
      return this.$store.getters.getDoses ?? [];
    },
    exportFilename() {
      const patient = this.$store.getters.getPatient
      const saved = this.$store.getters.getSelectedId
      return saved ? `${saved}_export.csv` : patient ? `${patient.id}_export.csv` : 'data_export.csv'
    },
    loaded() {
      return this.$store.getters.getModel !== null
    },
  },
  created() {
    window.addEventListener('keyup', (e) => {
      if (e.key === 'Escape') {
        if (this.editCell.row >= 0){
          this.editCell.row = -1;
          this.editCell.field = null;
          this.editCell.newValue = null;
          this.editCell.newUnit = null;
          this.editCell.valid = null;
          this.editCell.message = '';
        }
      }
    });
  },
  methods: {
    displaySIUnits,
    formatDate(value) {
      return new Date(value).toLocaleString();
    },
    deleteFromTable(item) {
      this.$store.dispatch('removeDose', item)
    },
    calculateSince() {
      this.$store.dispatch('saveDoses', this.items);
    },
    importModal() {
      this.$bvModal.show('import')
    },
    importDoses() {
      let err = null
      if (this.parsed) {
        const start = moment.now()
        this.content.data.forEach((row) => {
          const dose = importDoseFromRow(this.importSource, this.importFormat, row, start)
          if (dose instanceof Dose) {
            this.$store.dispatch('addDose', dose)
          } else {
            err = dose
          }
        })
        if (err) {
          this.$toast.error(err)
        } else {
          this.$toast.success('Data imported successfully')
        }
      } else {
        this.$toast.error('Data not imported')
      }
    },
    handleFileUpload($event) {
      this.file = $event.target.files[0];
      if (this.file.size < 3000) {
        this.parseFile();
      } else {
        this.$toast.error('File size is too big (max 3k)')
      }

    },
    parseFile() {
      const rtn = Papa.parse(this.file, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          this.content = results;
          this.parsed = true;
        }.bind(this)
      });
      if (rtn && rtn.errors && rtn.errors.length > 0) {
        this.$toast.error('Errors during parse')
        console.error(rtn.errors)
      }
    },
    editCellHandler(row, field){
      if (this.editCell.valid === false){
        // don't leave invalid cell
        return;
      }
      if (this.editCell.row >= 0){
        this.applyCellEdit();
      }
      if ('duration' === field){
        // only edit DOSE duration
        if ('DOSE' === this.items[row].type){
          this.editCell.row = row;
          this.editCell.field = field;
          this.editCell.newValue = this.items[row].duration;
          this.editCell.newUnit = this.items[row].durationUnit;
          this.editCell.valid = true;
          this.editCell.message = '';
        }
        else {
          // clear selection
          this.editCell.row = -1;
          this.editCell.field = null;
          this.editCell.newValue = null;
          this.editCell.newUnit = null;
          this.editCell.valid = null;
          this.editCell.message = '';
        }
      }
      else if ('amount' === field){
        this.editCell.row = row;
        this.editCell.field = field;
        this.editCell.newValue = this.items[row].amount;
        this.editCell.newUnit = this.items[row].amountUnit;
        this.editCell.valid = true;
        this.editCell.message = '';
      }
      else if ('datetime' === field){
        this.editCell.row = row;
        this.editCell.field = field;
        this.editCell.newValue = this.items[row].datetime.toLocaleString();
        this.editCell.newUnit = null;
        this.editCell.valid = null;
        this.editCell.message = '';
      }
      else {
        // clear selection
        this.editCell.row = -1;
        this.editCell.field = null;
        this.editCell.newValue = null;
        this.editCell.newUnit = null;
        this.editCell.valid = null;
        this.editCell.message = '';
      }
    },
    onEscape(){
      this.editCell.row = -1;
      this.editCell.field = null;
      this.editCell.newValue = null;
      this.editCell.newUnit = null;
      this.editCell.valid = null;
      this.editCell.message = '';
    },
    applyCellEdit(){
      if (this.editCell.row >= 0){
        if ('duration' === this.editCell.field){
            this.items[this.editCell.row].duration = this.editCell.newValue;
            this.items[this.editCell.row].durationUnit = this.editCell.newUnit;
        }
        else if ('amount' === this.editCell.field){
          this.items[this.editCell.row].amount = this.editCell.newValue;
          this.items[this.editCell.row].amountUnit = this.editCell.newUnit;
        }
        else if ('datetime' === this.editCell.field){
          this.items[this.editCell.row].datetime = moment(this.editCell.newValue, "DD/MM/YYYY, HH:mm:ss").toDate();
          this.calculateSince();
        }
      }
    },
    onRowClicked(item, index, event){
      if (this.editCell.valid === false){
        // don't leave invalid cell
        return;
      }
      if (this.editCell.row >= 0){
        this.applyCellEdit();
      }
      this.editCell.row = -1;
      this.editCell.field = null;
      this.editCell.newValue = null;
      this.editCell.newUnit = null;
      this.editCell.valid = null;
      this.editCell.message = '';
    },
    onEnter(){
      if (this.editCell.valid === false){
        // don't leave invalid cell
        return;
      }
      if (this.editCell.row >= 0){
        this.applyCellEdit();
      }
      this.editCell.row = -1;
      this.editCell.field = null;
      this.editCell.newValue = null;
      this.editCell.newUnit = null;
      this.editCell.valid = null;
      this.editCell.message = '';
    },
    onInput(){
      if (this.editCell.row >= 0) {
        const oldValid = this.editCell.valid;
        if ('duration' === this.editCell.field) {
          const unit = this.editCell.newUnit;
          const duration = this.editCell.newValue;
          const between = validations.duration[unit];
          this.editCell.valid = duration >= between[0] && duration <= between[1];
          this.editCell.message = this.editCell.valid ? ''
              : `Duration must be between ${between[0]} and ${between[1]} ${displaySIUnits(unit)}`;
        } else if ('amount' === this.editCell.field) {
          const unit = this.editCell.newUnit;
          const amount = this.editCell.newValue;
          const amountType = this.items[this.editCell.row].type;
          let between;
          if ('SeCR' === amountType){
            // amount is creatinine
            between = [validations.creatinine[unit].min, validations.creatinine[unit].max];
          }
          else if ('DOSE' === amountType){
            const convert = unit === 'g' ? 1000 : 1;
            const min = this.model.default.minDose / convert;
            const max = this.model.default.maxDose / convert;
            between = [min, max];
          }
          else if ('CONC' === amountType){
            between = [0.1, 10000];
          }
          else {
            this.editCell.valid = null;
            this.editCell.message = '';
            return;
          }
          this.editCell.valid = amount >= between[0] && amount <= between[1];
          this.editCell.message = this.editCell.valid ? ''
              : `Amount must be between ${between[0]} and ${between[1]} ${displaySIUnits(unit)}`;
        } else if ('datetime' === this.editCell.field) {
          const dtPattern = /^\d\d?\/\d\d?\/\d\d(\d\d)?, \d\d?:\d\d(:\d\d)?$/
          this.editCell.valid = dtPattern.test(this.editCell.newValue) && moment(this.editCell.newValue, "DD/MM/YYYY, HH:mm:ss").isValid();
          this.editCell.message = this.editCell.valid ? ''
              : `Invalid Date`;
        }
      }
    },
    unitsForType(type){
      const unitsToMap = type === 'DOSE' ? units.dose : type === 'CONC' ? units.concentration : units.creatinineConcentration;
      return unitsToMap.map((u) => {
        return {value: u, text: displaySIUnits(u)}
      });
    },
  }
}
</script>

<style scoped>
.form-label {
  font-weight: bold;
}

.modal-content .custom-control {
  text-align: left;
}

</style>