export class Member {
  constructor(param: { name: string, email: string, oid: string}) {
    this.id = Math.ceil(Math.random() * 1000000)
    this.name = param.name;
    this.email = param.email;
    this.oid = param.oid;
  }

  id: number | null | undefined;
  name: string;
  email: string;
  oid: string;
}
